import {
  postData,
  putData,
  isEmail,
  getQueryStringParams,
  differenceInSets,
} from "../../shared/utils";

const TREE_TYPES = {
  TEAM: "TEAM",
  MANAGEMENT: "MANAGEMENT",
  TEAMS_ONLY: "TEAMS_ONLY",
};

export function adminOrgCsvs(args) {
  const { currentUserEmail } = args;

  return {
    previewLoading: false,
    showInstructions: false,
    previewableImports: args.previewableImports,
    runningImports: args.runningImports,
    completedImports: args.completedImports,
    reviewing: !args.shadowing,
    isDragging: false,
    hasError: false,
    file: null,
    headers: [],
    rows: [],
    sampledRows: [],
    preprocessedTeams: [],
    fallbacksAdded: 0,
    extraLevels: [],
    activeImportId: null,
    newImport:
      getQueryStringParams().get("new") ||
      (!args.runningImports.length &&
        !args.completedImports.length &&
        !args.previewableImports.length),
    showCustomSettings: getQueryStringParams().get("custom_settings"),
    progress: 0,
    progressPayload: {},
    fields: [
      { key: "email", label: "Contributor email" },
      { key: "team_name", label: "Team name" },
      { key: "team_reference_id", label: "Team ID" },
      { key: "mgr_name", label: "Manager name" },
      { key: "mgr_email", label: "Team lead email" },
      { key: "parent_team", label: "Parent team name" },
      { key: "parent_team_dri", label: "Parent team lead email" },
      { key: "parent_team_reference_id", label: "Parent team ID" },
      { key: "grandparent_team", label: "Grandparent team name" },
      { key: "grandparent_team_dri", label: "Grandparent Team lead email" },
      { key: "grandparent_team_reference_id", label: "Grandparent Team ID" },
      { key: "exclude", label: "Exclude from snapshot (true/false)" },
    ],
    selectedColumns: {
      name: "name",
      email: "email",
      team_name: "direct_team",
      team_reference_id: "team_reference_id",
      mgr_name: "manager_name",
      mgr_email: "manager_email",
      parent_team: "parent_team",
      parent_team_reference_id: "parent_team_reference_id",
      parent_team_dri: "parent_team_dri",
      grandparent_team: "grandparent_team",
      grandparent_team_reference_id: "grandparent_team_reference_id",
      grandparent_team_dri: "grandparent_team_dri",
      exclude: "exclude",
    },
    parsedRows: [],
    showPreview: false,
    teamsPreview: {},
    squads: [],
    treeType: TREE_TYPES.TEAM, // "MANAGEMENT" or "TEAM" or "TEAMS_ONLY"
    treeTypes: Object.keys(TREE_TYPES),
    expandedSquadsIds: {},
    customSettings: {
      minGroupSize: 0,
      prunedTeams: [],
      associationsOnly: false,
      includeManagersAsOwnTeamMembers: false,
      teamUniqueness: "teamNameAndManagerEmail", // Possible values: teamNameOnly, teamNameAndManagerEmail, teamNameAndParentTeam, referenceIds
      managerTeamsAsParentTeams: false,
      teamNamePruning: "",
      resetAllTeams: false,
      userMembershipsOnly: false,
      saveForPreview: false,
    },
    employeeEmailSet: new Set(),
    showUserDiff: false,
    userDiff: [],
    showJson: false,
    showTeamsList: false,
    searchResults: {
      memberOf: [],
      managerOf: [],
    },
    init() {
      if (this.treeType == TREE_TYPES.TEAMS_ONLY) {
        this.customSettings.associationsOnly = true;
      }

      // Set initial URL state if not already set
      if (!window.location.pathname.endsWith("/preview")) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("new") === "true") {
          this.changeRoute("new");
        }
      }

      // Check for preview/{id} in URL and set activeImportId if found
      const pathMatch = window.location.pathname.match(/\/preview\/([^/]+)/);
      if (pathMatch && pathMatch[1]) {
        this.activeImportId = atob(pathMatch[1]);
        if (this.isPreviewableImport) {
          this.initializePreviewableImport(this.activeImportId);
        }
      }
    },
    get isPreviewableImport() {
      return !!this.previewableImports.find((i) => i.id == this.activeImportId);
    },
    get activePreviewableImport() {
      return this.previewableImports.find((i) => i.id == this.activeImportId);
    },
    set activePreviewableImport(imp) {
      this.previewableImports = this.previewableImports.map((i) =>
        i.id == this.activeImportId ? imp : i,
      );
    },
    get columnsAreValid() {
      // TODO: More logic needed here for validity.
      let valid = false;

      const row = this.sampleRandomRows(1)?.[0];
      const email = row && row[this.selectedColumns["email"]];

      if (this.treeType == TREE_TYPES.TEAM) {
        if (
          isEmail(email) &&
          row[this.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      } else if (this.treeType == TREE_TYPES.MANAGEMENT) {
        if (isEmail(email) && isEmail(row[this.selectedColumns["mgr_email"]]))
          valid = true;
      } else if (this.treeType == TREE_TYPES.TEAMS_ONLY) {
        const manager_email = row && row[this.selectedColumns["mgr_email"]];
        if (
          isEmail(manager_email) &&
          row[this.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      }

      return valid;
    },
    get treeSquads() {
      this.squads.map((s) => {
        return s;
      });

      const squadsToReturn = this.squads?.filter((s) => {
        const value =
          s.depth == 0 ||
          s.expanded == true ||
          this.expandedSquadsIds[s.parent_id] == true;

        return value;
      });

      return squadsToReturn;
    },
    get topLevelSquads() {
      return this.squads?.filter((s) => s.depth == 0);
    },
    get teamsMissingFromList() {
      const missingTeams = this.preprocessedTeams?.filter(
        (t) => !this.squads.find((s) => s.team === t.team),
      );
      return missingTeams;
    },
    get duplicateTeamNames() {
      const teamNameMap = new Map();

      this.squads.forEach((squad) => {
        const teamName = squad.team;
        const managerEmail = squad.mgr_email;
        const parentTeam = this.squads.find(
          (s) => s.temp_id === squad.parent_id,
        )?.team;

        if (!teamNameMap.has(teamName)) {
          teamNameMap.set(teamName, new Map());
        }

        const teamInfo = teamNameMap.get(teamName);
        if (!teamInfo.has(managerEmail)) {
          teamInfo.set(managerEmail, new Set());
        }
        if (parentTeam) {
          teamInfo.get(managerEmail).add(parentTeam);
        }
      });

      const duplicates = [];

      teamNameMap.forEach((managerInfo, teamName) => {
        if (managerInfo.size > 1) {
          const managers = Array.from(managerInfo.entries()).map(
            ([email, parents]) => ({
              email,
              parentTeams: Array.from(parents),
            }),
          );
          duplicates.push({
            team: teamName,
            managers: managers.map((m) =>
              m.parentTeams.length
                ? `${m.email} (Parent: ${m.parentTeams.join(", ")})`
                : m.email,
            ),
          });
        }
      });

      return duplicates;
    },
    setActiveImport(importId) {
      this.activeImportId = importId;
      if (this.isPreviewableImport) {
        this.initializePreviewableImport(this.activeImportId);
      }
    },
    initializePreviewableImport() {
      this.treeType = this.activePreviewableImport.tree_type || TREE_TYPES.TEAM;
      this.customSettings.teamUniqueness =
        this.activePreviewableImport.team_uniqueness ||
        "teamNameAndManagerEmail";
      this.showPreview = true;
      // Get a sample row from rows_json
      const sampleRow = this.activePreviewableImport.rows_json[0];
      if (sampleRow) {
        // Find all extra_level_x fields by checking keys
        const extraLevels = Object.keys(sampleRow)
          .filter((key) => key.match(/^extra_level_\d+$/))
          .map((key) => parseInt(key.replace("extra_level_", "")))
          .sort((a, b) => a - b);

        extraLevels.forEach((level) => {
          this.addLevel(level);
        });
      }
      this.preview();
    },
    addLevel() {
      const newLevel = this.extraLevels.length + 1;
      this.extraLevels.push(newLevel);

      // Add the new level's columns to selectedColumns
      this.selectedColumns[`extra_level_${newLevel}`] =
        `extra_level_${newLevel}`;
      this.selectedColumns[`extra_level_${newLevel}_dri`] =
        `extra_level_${newLevel}_dri`;
      this.selectedColumns[`extra_level_${newLevel}_reference_id`] =
        `extra_level_${newLevel}_reference_id`;
    },
    goBack() {
      this.showPreview = false;
      if (this.activeImportId) {
        this.newImport = false;
      }
      this.activeImportId = null;
      this.changeRoute("");
    },
    changeRoute(path) {
      const currentUrl = new URL(window.location.href);
      const newUrl = new URL(`/admin/org_csvs/${path}`, window.location.origin);
      currentUrl.searchParams.forEach((value, key) => {
        newUrl.searchParams.set(key, value);
      });
      window.history.pushState({}, "", newUrl.toString());
    },
    searchUsers(email) {
      console.log(`searching for email:`, email);
      if (!email || email.length < 3) return [];

      const searchTerm = email.toLowerCase();
      const memberOfResults = this.squads?.filter((squad) =>
        squad.members?.some((member) =>
          member.toLowerCase().includes(searchTerm),
        ),
      );

      const managerOfResults = this.squads?.filter((squad) =>
        squad.mgr_email?.toLowerCase().includes(searchTerm),
      );

      // Add parent_team field to managerOfResults by looking up parent_id
      managerOfResults.forEach((team) => {
        if (team.parent_id) {
          const parentTeam = this.squads?.find(
            (s) => s.temp_id === team.parent_id,
          );
          if (parentTeam) {
            team.grandparent_team = parentTeam.parent_team_name;
          }
        }
      });

      // Add parent_team field to memberOfResults by looking up parent_id
      memberOfResults.forEach((team) => {
        if (team.parent_id) {
          const parentTeam = this.squads?.find(
            (s) => s.temp_id === team.parent_id,
          );
          console.log(`parentTeam:`, parentTeam);
          if (parentTeam) {
            team.found_parent_team = parentTeam.team;
            team.grandparent_team = parentTeam.parent_team_name;
          }
        }
      });

      this.searchResults = {
        memberOf: memberOfResults,
        managerOf: managerOfResults,
      };
      console.log(`this.searchResults:`, this.searchResults);
    },
    childTeamsOf(squad) {
      const childSquads = this.squads?.filter(
        (s) => s.parent_id == squad.temp_id,
      );
      return childSquads;
    },
    formatDate(date) {
      const d = new Date(date);
      // Get the hours and minutes
      const hours = d.getHours();
      const minutes = d.getMinutes().toString().padStart(2, "0");
      const seconds = d.getSeconds().toString().padStart(2, "0");

      // Determine AM/PM
      const ampm = hours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Format date using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(d);

      // Return the formatted string
      return `${formattedHours}:${minutes}:${seconds}${ampm} on ${formattedDate}`;
    },
    handleDrop(event) {
      this.hasError = false;
      event?.preventDefault();

      // Handle both drag & drop and file input
      const file =
        event.type === "drop"
          ? event.dataTransfer.files[0]
          : event.target.files[0];

      this.file = file;
      this.isDragging = false;
      this.validateFile();
    },
    processFile() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.parseCSV(csvContent);
        };
        reader.readAsText(this.file);
      }
    },
    validateFile() {
      this.errorMessage = "";
      if (this.file) {
        // Check the MIME type or extension
        const mimeType = this.file.type;
        const fileName = this.file.name;

        // Validate MIME type or file extension
        if (
          mimeType !== "text/csv" &&
          mimeType !== "application/vnd.ms-excel" &&
          !fileName.endsWith(".csv")
        ) {
          this.errorMessage = "Invalid file type. Please upload a CSV file.";
          this.hasError = true;
          this.file = null;
          return;
        }

        this.processFile(); // Process only if it's a valid CSV file
      }
    },
    async parseCSV(csvContent) {
      const Papa = await import("papaparse");
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          this.headers = result.meta.fields;
          // Replace #REF! with empty strings in each row
          this.rows = result.data.map((row) => {
            const cleanedRow = {};
            for (const key in row) {
              cleanedRow[key] =
                row[key] === "#REF!" || row[key] === "#N/A" ? "" : row[key];
            }
            return cleanedRow;
          });
          this.sampledRows = this.sampleRandomRows();
        },
        skipEmptyLines: true,
      });
    },
    sampleRandomRows(n = 20) {
      if (this.rows.length > 0) {
        // Shuffle the rows and take a random sample of 20 rows
        const shuffled = [...this.rows].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, n);
      }
    },
    async preview() {
      this.previewLoading = true;
      this.showPreview = true;

      // Force a UI update before heavy processing begins
      await new Promise((resolve) => setTimeout(resolve, 0));

      if (this.isPreviewableImport) {
        this.rows = this.activePreviewableImport.rows_json;
      }

      try {
        const managersSet = new Set();

        const employees = this.rows.map((row) => {
          if (
            row[this.selectedColumns["email"]] &&
            row[this.selectedColumns["exclude"]] != "TRUE"
          )
            this.employeeEmailSet.add(
              row[this.selectedColumns["email"]]?.toLowerCase(),
            );

          const extraLevelsData = this.extraLevels.reduce(
            (acc, level) => ({
              ...acc,
              [`extra_level_${level}`]:
                row[this.selectedColumns[`extra_level_${level}`]],
              [`extra_level_${level}_dri`]:
                row[
                  this.selectedColumns[`extra_level_${level}_dri`]
                ]?.toLowerCase(),
              [`extra_level_${level}_reference_id`]:
                row[this.selectedColumns[`extra_level_${level}_reference_id`]],
            }),
            {},
          );

          return {
            email: row[this.selectedColumns["email"]]?.toLowerCase(),
            name: row[this.selectedColumns["team_name"]],
            team: row[this.selectedColumns["team_name"]],
            team_reference_id: row[this.selectedColumns["team_reference_id"]],
            mgr_name: row[this.selectedColumns["mgr_name"]], // TODO: fallback logic here to traverse back through the fallbacks if they don't exist
            mgr_email: row[this.selectedColumns["mgr_email"]]?.toLowerCase(),
            parent_team: row[this.selectedColumns["parent_team"]],
            parent_team_reference_id:
              row[this.selectedColumns["parent_team_reference_id"]],
            parent_team_dri:
              this.selectedColumns["parent_team_dri"] === "current_user_email"
                ? currentUserEmail
                : row[this.selectedColumns["parent_team_dri"]]?.toLowerCase(),
            grandparent_team: row[this.selectedColumns["grandparent_team"]],
            grandparent_team_reference_id:
              row[this.selectedColumns["grandparent_team_reference_id"]],
            grandparent_team_dri:
              row[this.selectedColumns["grandparent_team_dri"]]?.toLowerCase(),
            exclude: row[this.selectedColumns["exclude"]] == "TRUE",
            ...extraLevelsData,
          };
        });

        await new Promise((resolve) => setTimeout(resolve, 0));

        this.parsedRows = employees;
        console.log(`employees:`, employees);

        if (this.isPreviewableImport) {
          const activeImport = this.previewableImports.find(
            (i) => i.id == this.activeImportId,
          );
          this.changeRoute(`preview/${activeImport.encoded_id}`);
        } else if (this.activeImportId && !this.isPreviewableImport) {
          this.goBack();
        }

        if (
          this.treeType === TREE_TYPES.MANAGEMENT &&
          !this.selectedColumns["mgr_email"]
        ) {
          this.treeType = TREE_TYPES.TEAM;
        }

        // const preprocessedManagerDataPinterest = null//this.preprocessManagerTeams( employees, managersSet,);

        const pinterestHierarchy = null; //this.buildTeamHierarchy( preprocessedManagerDataPinterest,);

        this.preprocessedTeams =
          this.treeType == TREE_TYPES.TEAM ||
          this.treeType == TREE_TYPES.TEAMS_ONLY
            ? this.preprocessTeams(
                this.parsedRows,
                managersSet,
                this.treeType == TREE_TYPES.TEAMS_ONLY,
              )
            : this.preprocessManagerTeams(this.parsedRows);

        console.log(`this.preprocessedTeams:`, this.preprocessedTeams);

        await new Promise((resolve) => setTimeout(resolve, 0));

        if (!pinterestHierarchy) {
          const result =
            this.treeType == TREE_TYPES.MANAGEMENT
              ? this.buildOrgHierarchy(this.parsedRows)
              : this.buildTeamHierarchy(this.preprocessedTeams);
          this.teamsPreview = result;
          console.log(`result:`, result);
        } else {
          this.teamsPreview = pinterestHierarchy;
        }

        await new Promise((resolve) => setTimeout(resolve, 0));

        const flattenedSquads =
          this.treeType == TREE_TYPES.MANAGEMENT
            ? this.flattenOrgHierarchy(this.teamsPreview)
            : this.flattenTeamHierarchy(
                this.teamsPreview,
                this.preprocessedTeams,
              ); // May need a different flattening process based on the tree type

        this.squads = flattenedSquads;

        const diff = differenceInSets(
          this.employeeEmailSet,
          this.uniqueMembers(this.squads),
        );
        this.userDiff = Array.from(diff);
      } catch (error) {
        console.error("Error in preview:", error);
        throw error;
      } finally {
        this.previewLoading = false;
      }
    },
    buildOrgHierarchy(employees) {
      const customSettings = this.customSettings;
      // Create a map of all employees by their email
      const employeeMap = new Map();
      employees.forEach((emp) => {
        // Add an entry for the employee
        employeeMap.set(emp.email, emp);
        // Add an entry for the manager if it doesn't exist
        if (emp.mgr_email && !employeeMap.has(emp.mgr_email)) {
          employeeMap.set(emp.mgr_email, {
            email: emp.mgr_email,
            name: emp.mgr_name,
            mgr_email: null,
            mgr_name: null,
          });
        }
      });

      // Enrich employee map with names from manager data
      employees.forEach((emp) => {
        if (emp.mgr_email && emp.mgr_name) {
          const manager = employeeMap.get(emp.mgr_email);
          if (manager && !manager.name) {
            manager.name = emp.mgr_name;
            employeeMap.set(emp.mgr_email, manager);
          }
        }
      });

      // Find the top-level manager(s)
      const topManagers = Array.from(employeeMap.values()).filter(
        (emp) => !emp.mgr_email || emp.mgr_email === "",
      );
      console.log(`topManagers:`, topManagers);

      let teamIdCounter = 1;

      // Function to recursively build the hierarchy
      function buildHierarchy(manager, depth = 1, parent_id) {
        const temp_id = `T${teamIdCounter++}`;
        const subordinates = employees.filter(
          (emp) => emp.mgr_email === manager.email,
        );

        const getName = (email) => {
          const emp = employeeMap.get(email);
          return emp && emp.name ? emp.name : email;
        };

        // Build subTeams first so we can check if they exist
        const subTeams = subordinates
          .map((sub) => buildHierarchy(sub, depth + 1, temp_id))
          .filter(Boolean); // Remove null/undefined results

        let members = [...subordinates.map((sub) => sub.email)];
        if (customSettings.includeManagersAsOwnTeamMembers) {
          members.push(manager.email);
        }

        const teamName =
          manager.name ||
          (subTeams.length > 0 ? getName(manager.email) : manager.email);

        // Only return a team if it has members or valid subTeams
        if (members.length === 0 && subTeams.length === 0) {
          return null;
        }

        return {
          name: teamName,
          team: teamName,
          email: manager.email,
          mgr_email: manager.email,
          parent_team: manager.parent_team,
          members,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          subTeams: subTeams,
        };
      }

      // Build the hierarchy for each team
      let hierarchy = topManagers.map((team) => {
        const temp_id = `T${teamIdCounter++}`;

        const objToReturn = {
          team: team.name,
          name: team.name,
          members: [],
          email: team.email,
          depth: 0,
          temp_id: temp_id,
          is_parent: true,
          subTeams: [buildHierarchy(team, 1, temp_id)],
        };

        objToReturn.subTeams = objToReturn.subTeams.filter((team) => {
          if (
            team.is_parent ||
            team.members.length > 0 ||
            team.name ||
            team.subTeams.length > 0
          ) {
            return true;
          }
          objToReturn.members = [...objToReturn.members, team.email];
          return false;
        });

        //return objToReturn;
        // NOTE: be mindful of all the logic I just removed here.
        return buildHierarchy(team, 0, temp_id);
      });

      // Apply pruning after the hierarchy is built
      if (customSettings.minGroupSize > 0) {
        const prunedTeams = [];

        function pruneSmallTeams(node, parent = null) {
          if (!node) return null;

          // Process subTeams first
          if (node.subTeams && node.subTeams.length > 0) {
            node.subTeams = node.subTeams
              .map((subTeam) => pruneSmallTeams(subTeam, node))
              .filter((subTeam) => subTeam !== null);
          }

          // After processing subTeams, check if current node is a leaf
          const isLeaf = !node.subTeams || node.subTeams.length === 0;

          if (isLeaf && node.members.length < customSettings.minGroupSize) {
            if (parent) {
              // Move members up to parent team
              parent.members = [...parent.members, ...node.members];

              // Record the pruned team
              prunedTeams.push({
                removedTeam: node.team,
                movedMemberCount: node.members.length,
                movedMembers: node.members,
                newSourceTeam: parent.team,
              });

              // Remove this node by returning null
              return null;
            }
          }

          // Update is_parent flag based on subTeams
          node.is_parent = node.subTeams && node.subTeams.length > 0;
          return node;
        }

        // Apply pruning to the hierarchy
        hierarchy = hierarchy
          .map((team) => pruneSmallTeams(team))
          .filter((team) => team !== null);

        this.customSettings.prunedTeams = prunedTeams.sort((a, b) =>
          a.removedTeam.localeCompare(b.removedTeam),
        );
      }

      return hierarchy.sort((a, b) => {
        // Sort by is_parent first
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        // If is_parent is the same, sort alphabetically by team
        return a.team.localeCompare(b.team);
      });
    },
    flattenTeamHierarchy(hierarchy, preprocessedTeams) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        // Lookup the node in preprocessedTeams and ensure the members list is the same
        const matchingTeam = preprocessedTeams.find(
          (preprocessedTeam) => preprocessedTeam.team === node.name,
        );
        let members = [];

        if (matchingTeam) {
          members = matchingTeam.members; // Use members from preprocessedTeam if found
        } else {
          members = node.members;
        }

        flatArray.push({
          team: node?.team,
          name: node.name,
          reference_id: node.reference_id,
          email: node.email,
          mgr_email: node.mgr_email,
          temp_id: node.temp_id,
          parent_team_reference_id: node.parent_team_reference_id,
          parent_id: parentId,
          is_parent: node.is_parent,
          depth: node.depth,
          members,
          parent_team_name: node.parent_team,
        });

        // Recursively flatten the subTeams (children)
        node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    flattenOrgHierarchy(hierarchy) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        // Lookup the node in preprocessedTeams and ensure the members list is the same

        // TODO: Almost there here. this is not quite the right condition
        // basically what's happening is that it's flattening a separate team for each contributor
        // it only needs to do it for teams with people
        if (node?.members?.length > 0 || node?.subTeams?.length > 0) {
          flatArray.push({
            name: node.name,
            email: node.email,
            mgr_email: node.mgr_email,
            team: node?.team,
            temp_id: node.temp_id,
            parent_id: parentId,
            is_parent: node.is_parent,
            depth: node.depth,
            members: node.members,
            subTeamsCount: node.subTeams.length,
            parent_team_name: node.parent_team,
          });

          // Recursively flatten the subTeams (children)
          node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
        }
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    preprocessManagerTeams(employees) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        const teamKey = `${emp.mgr_name}-${emp.mgr_email}`;

        if (!teamMap.has(teamKey)) {
          // Add the team if it doesn't already exist
          teamMap.set(teamKey, {
            team: emp.mgr_name,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            parent_team: emp.parent_team || null,
            parent_team_dri: emp.parent_team_dri || null,
            grandparent_team: emp.grandparent_team || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            members: [],
          });
        }

        // Adding only direct IC's team
        // if (!managersSet.has(emp.email)) {
        teamMap.get(teamKey).members.push(emp.email);
        // }
      });

      // Second Pass: Assign parent_team based on mgr_email
      teamMap.forEach((teamValue) => {
        if (teamValue.mgr_email) {
          // Find the manager's employee record
          const manager = employees.find(
            (emp) => emp.email === teamValue.mgr_email,
          );
          if (manager && manager.team) {
            // Assign the manager's team as the parent_team
            teamValue.parent_team = manager.team;
          }
        }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    preprocessTeams(employees, managersSet, isTeamsOnly = false) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        let teamName = emp.team;
        let parentTeamName = emp.parent_team;
        let grandparentTeamName = emp.grandparent_team;

        // Strip pruning strings if configured
        if (this.customSettings.teamNamePruning) {
          const pruningTerms = this.customSettings.teamNamePruning
            .split(",")
            .map((term) => term.trim());
          pruningTerms.forEach((term) => {
            if (teamName) teamName = teamName.replace(term, "").trim();
            if (parentTeamName)
              parentTeamName = parentTeamName.replace(term, "").trim();
            if (grandparentTeamName)
              grandparentTeamName = grandparentTeamName
                .replace(term, "")
                .trim();
          });
        }

        const empWithPrunedNames = {
          ...emp,
          team: teamName,
          parent_team: parentTeamName,
          grandparent_team: grandparentTeamName,
        };

        let empToUse = empWithPrunedNames;

        if (!teamMap.has(this.teamKey(empWithPrunedNames))) {
          // Add the team if it doesn't already exist
          const extraLevelsData = this.extraLevels.reduce(
            (acc, level) => ({
              ...acc,
              [`extra_level_${level}`]: emp[`extra_level_${level}`] || null,
              [`extra_level_${level}_dri`]:
                emp[`extra_level_${level}_dri`] || null,
              [`extra_level_${level}_reference_id`]:
                emp[`extra_level_${level}_reference_id`] || null,
            }),
            {},
          );

          empToUse = {
            team: teamName,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            reference_id: emp.team_reference_id || null,
            parent_team: parentTeamName || null,
            parent_team_dri: emp.parent_team_dri || null,
            parent_team_reference_id: emp.parent_team_reference_id || null,
            grandparent_team: grandparentTeamName || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            grandparent_team_reference_id:
              emp.grandparent_team_reference_id || null,
            members: [],
            ...extraLevelsData,
          };

          teamMap.set(this.teamKey(empToUse), empToUse);
        }

        if (!isTeamsOnly && !emp.exclude) {
          teamMap.get(this.teamKey(empToUse)).members.push(emp.email);
        }
      });

      if (this.customSettings.managerTeamsAsParentTeams) {
        // Iterate through each team to find parent teams based on manager membership
        teamMap.forEach((teamValue, teamKey) => {
          // Only process teams that don't already have a parent team assigned
          if (!teamValue.parent_team && teamValue.mgr_email) {
            // Search through all teams to find where the manager is a member
            teamMap.forEach((potentialParentTeam, potentialParentKey) => {
              if (
                potentialParentKey !== teamKey && // Don't match self
                potentialParentTeam.members.includes(teamValue.mgr_email)
              ) {
                // Found the team where the manager is a member - set as parent
                teamValue.parent_team = potentialParentTeam.team;
                teamValue.parent_team_dri = potentialParentTeam.mgr_email;
              }
            });
          }
        });
      }

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    teamKey(team) {
      switch (this?.customSettings?.teamUniqueness) {
        case "referenceIds":
          if (team.reference_id) {
            return team.reference_id;
          }
          break;
        case "teamNameOnly":
          return team.team;
        case "teamNameAndParentTeam":
          return `${team.team}-${team?.parent_team || null}`;
        case "teamNameAndManagerEmail":
        default:
          return `${team.team}-${team.mgr_email}`;
      }
    },
    buildTeamHierarchy(teams) {
      const getTeamKey = this.teamKey.bind(this);

      // Step 1: Create a map for quick team lookup by team key
      const teamMap = new Map();
      teams.forEach((team) => {
        teamMap.set(getTeamKey(team), team);
      });

      // Step 2: Insert missing parent teams
      const allTeams =
        this.extraLevels.length > 0
          ? this.generateAllTeams(teams)
          : this.insertMissingParents(teams, teamMap);

      // Step 3: Identify Top-Level Teams
      const topTeamsSet = new Set();

      allTeams.forEach((team) => {
        if (!team.parent_team && team.team) {
          topTeamsSet.add(team.team);
        }
      });

      if (topTeamsSet.size == 0 || this.treeType == TREE_TYPES.TEAMS_ONLY) {
        topTeamsSet.clear();

        if (this.extraLevels.length > 0) {
          // Get the highest extra level
          const highestLevel = Math.max(...this.extraLevels);
          // Use the highest extra level teams as top level
          allTeams.forEach((team) => {
            // console.log(`team:`, team);
            if (team.team && team[`extra_level_${highestLevel}`]) {
              topTeamsSet.add(team[`extra_level_${highestLevel}`]);
            }
          });
        } else {
          // Fallback to using grandparent teams if no extra levels
          allTeams.forEach((team) => {
            if (team.team && team.grandparent_team) {
              topTeamsSet.add(team.grandparent_team);
            }
          });
        }
      }
      const topTeamsArray = Array.from(topTeamsSet);
      console.log(`topTeamsSet:`, topTeamsSet);
      const teamsWithReferenceId = allTeams.filter(
        (team) => team.reference_id,
      ).length;
      console.log(`allTeams:`, allTeams);
      if (teamsWithReferenceId == allTeams.length) {
        this.customSettings.teamUniqueness = "referenceIds";
      }

      // Convert the Set to an Array of team objects
      const topTeams = topTeamsArray
        .map((teamName) => allTeams.find((team) => team.team === teamName))
        .filter((team) => team); // Filter out undefined if any

      console.log(`topTeams:`, topTeams);

      let teamIdCounter = 1;
      const visited = new Set();
      const customSettings = this.customSettings;

      // Recursive function to build the hierarchy
      function buildHierarchy(
        team,
        depth = 0,
        parent_id = null,
        parent_reference_id = null,
      ) {
        const temp_id = `T${teamIdCounter++}`;

        // Detect circular references
        const teamKey = getTeamKey(team);
        if (visited.has(teamKey)) {
          console.warn(`Circular reference detected for team: ${team.team}`);
          return null;
        }
        visited.add(teamKey);

        // Find sub-teams based on parent_team or reference_id
        const subTeams = allTeams
          .filter((sub) => {
            // If we have reference IDs, use them
            if (team.reference_id && sub.parent_team_reference_id) {
              return sub.parent_team_reference_id === team.reference_id;
            }

            // Otherwise, fall back to team name matching
            if (sub.parent_team === team.team) {
              return true;
            }

            // Check grandparent relationship
            if (sub.grandparent_team === team.team) {
              const intermediateTeam = allTeams.find(
                (t) => t.team === sub.parent_team,
              );
              if (!intermediateTeam) {
                return true;
              }
            }

            return false;
          })
          .map((sub) =>
            buildHierarchy(sub, depth + 1, temp_id, team.reference_id),
          )
          .filter((subTeam) => !!subTeam);

        const originalTeam = teamMap.get(teamKey);
        const members =
          (originalTeam ? originalTeam.members : team.members) || [];

        if (team.mgr_email && customSettings?.includeManagersAsOwnTeamMembers) {
          members.push(team.mgr_email);
        }

        const teamToReturn = {
          name: team.team,
          team: team.team,
          reference_id: team.reference_id || null,
          parent_team: team.parent_team || null,
          parent_team_reference_id: parent_reference_id || null,
          mgr_email: team.mgr_email || null,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          members,
          subTeams: subTeams.sort((a, b) => {
            if (a.is_parent !== b.is_parent) {
              return b.is_parent - a.is_parent;
            }
            return a.team.localeCompare(b.team);
          }),
        };

        return teamToReturn;
      }

      // Step 4: Build the hierarchy starting from top-level teams
      const hierarchy = topTeams
        .map((team) => buildHierarchy(team))
        .filter((team) => team);

      const minGroupSize = this.customSettings.minGroupSize;
      const prunedTeams = [];

      // Prune small leaf teams
      function pruneSmallTeams(node, parent = null) {
        if (!node) return null;

        // Process subTeams first
        if (node.subTeams && node.subTeams.length > 0) {
          node.subTeams = node.subTeams
            .map((subTeam) => pruneSmallTeams(subTeam, node))
            .filter((subTeam) => subTeam !== null);
        }

        // After processing subTeams, check if current node is a leaf
        const isLeaf = !node.subTeams || node.subTeams.length === 0;

        if (isLeaf && node.members.length < minGroupSize) {
          if (parent) {
            // Initialize parent.members as array if needed
            if (!Array.isArray(parent.members)) {
              parent.members = [];
            }

            // Add unique members from node to parent
            node.members.forEach((member) => {
              if (!parent.members.includes(member)) {
                parent.members.push(member);
              }
            });

            // Record the pruned team
            prunedTeams.push({
              removedTeam: node.team,
              movedMemberCount: node.members.length,
              movedMembers: node.members,
              newSourceTeam: parent.team,
            });

            // Remove this node by returning null
            return null;
          } else {
            // If there's no parent, we cannot prune this team
            return node;
          }
        } else {
          // Update is_parent flag based on subTeams
          node.is_parent = node.subTeams && node.subTeams.length > 0;
          return node;
        }
      }

      // Bind the function to access 'this'
      const boundPruneSmallTeams = pruneSmallTeams.bind(this);

      let finalHierarchy;
      // Apply pruning to the hierarchy
      if (minGroupSize > 0) {
        finalHierarchy = hierarchy
          .map((team) => boundPruneSmallTeams(team))
          .filter((team) => team !== null);
      } else {
        finalHierarchy = hierarchy;
      }

      this.customSettings.prunedTeams = prunedTeams.sort((a, b) =>
        a.removedTeam.localeCompare(b.removedTeam),
      );

      const sortedHierarchy = finalHierarchy.sort((a, b) => {
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        return a.team.localeCompare(b.team);
      });

      return sortedHierarchy;
    },
    generateAllTeams(teams) {
      const teamsMap = new Map();
      const getTeamKey = this.teamKey.bind(this);

      // Helper function to safely add a team to the map
      const addTeamToMap = (team) => {
        if (!team?.team) return; // Skip if team name is missing
        const key = getTeamKey(team);
        if (!teamsMap.has(key)) {
          teamsMap.set(key, team);
        }
      };

      teams.forEach((team) => {
        // Add leaf team
        addTeamToMap(team);

        // Add parent team
        const parentTeam = {
          team: team.parent_team,
          mgr_email: team.parent_team_dri,
          reference_id: team.parent_team_reference_id,
          parent_team: team.grandparent_team,
          parent_team_dri: team.grandparent_team_dri,
          parent_team_reference_id: team.grandparent_team_reference_id,
        };
        addTeamToMap(parentTeam);

        // Add grandparent team
        const grandParentTeam = {
          team: team.grandparent_team,
          mgr_email: team.grandparent_team_dri,
          reference_id: team.grandparent_team_reference_id,
          parent_team: team.extra_level_1,
          parent_team_dri: team.extra_level_1_dri,
          parent_team_reference_id: team.extra_level_1_reference_id,
        };
        addTeamToMap(grandParentTeam);

        // Add extra level teams
        let previousLevel = grandParentTeam;
        this.extraLevels.forEach((level) => {
          const extraTeam = {
            team: team[`extra_level_${level}`],
            mgr_email: team[`extra_level_${level}_dri`],
            reference_id: team[`extra_level_${level}_reference_id`],
            parent_team: team[`extra_level_${level + 1}`],
            parent_team_dri: team[`extra_level_${level + 1}_dri`],
            parent_team_reference_id:
              team[`extra_level_${level + 1}_reference_id`],
          };

          // Only add if we have a valid team name and haven't created a circular reference
          if (extraTeam.team && extraTeam.team !== previousLevel?.team) {
            addTeamToMap(extraTeam);
            previousLevel = extraTeam;
          }
        });
      });

      return Array.from(teamsMap.values()).filter((team) => team.team); // Filter out any invalid teams
    },
    insertMissingParents(teams, teamMap) {
      console.log(`insertMissingParents called...`);
      const getTeamKey = this.teamKey.bind(this);
      const allTeams = [...teams];
      let addedNewTeam;

      do {
        addedNewTeam = false;
        const currentTeams = [...allTeams];

        currentTeams.forEach((team) => {
          // Handle missing parent_team
          if (
            team.parent_team &&
            !teamMap.has(
              getTeamKey({
                team: team.parent_team,
                mgr_email: team.parent_team_dri,
                parent_team: team.grandparent_team,
                parent_team_reference_id: team.grandparent_team_reference_id,
                reference_id: team.parent_team_reference_id,
              }),
            )
          ) {
            if (team.grandparent_team) {
              // Create a placeholder for the missing parent_team
              const parentTeam = {
                team: team.parent_team,
                reference_id: team.parent_team_reference_id,
                mgr_email: team.parent_team_dri,
                parent_team: team.grandparent_team,
                parent_team_dri: team.grandparent_team_dri,
                parent_team_reference_id: team.grandparent_team_reference_id,
                grandparent_team: null,
                members: [],
              };
              // Add the new parent team only if it hasn't been added already
              if (!teamMap.has(getTeamKey(parentTeam))) {
                allTeams.push(parentTeam);
                teamMap.set(getTeamKey(parentTeam), parentTeam);
                addedNewTeam = true;
                console.log(
                  `Added missing parent team: ${parentTeam.team}`,
                  parentTeam,
                );
              }

              // Additionally, ensure that the grandparent_team exists
              if (
                parentTeam.parent_team &&
                !teamMap.has(
                  getTeamKey({
                    team: parentTeam.parent_team,
                    mgr_email: parentTeam.parent_team_dri,
                    parent_team: parentTeam?.grandparent_team || null,
                  }),
                )
              ) {
                const grandparentTeam = {
                  team: parentTeam.parent_team,
                  reference_id: team.grandparent_team_reference_id,
                  mgr_email: parentTeam.parent_team_dri,
                  parent_team: null,
                  grandparent_team: null,
                  members: [],
                };
                if (!teamMap.has(getTeamKey(grandparentTeam))) {
                  allTeams.push(grandparentTeam);
                  teamMap.set(getTeamKey(grandparentTeam), grandparentTeam);
                  addedNewTeam = true;
                  console.log(
                    `Added grandparent team: ${grandparentTeam.team}`,
                    grandparentTeam,
                    `parent_team`,
                    parentTeam,
                  );
                }
              }
            } else {
              // If there's no grandparent_team, consider parent_team as a top-level team
              const parentTeam = {
                team: team.parent_team,
                reference_id: team.parent_team_reference_id,
                mgr_email: team.parent_team_dri,
                parent_team: null,
                grandparent_team: null,
                members: [],
              };
              if (!teamMap.has(getTeamKey(parentTeam))) {
                allTeams.push(parentTeam);
                teamMap.set(getTeamKey(parentTeam), parentTeam);
                addedNewTeam = true;
                console.log(`Added top-level parent team: ${parentTeam.team}`);
              }
            }
          }

          // Handle missing grandparent_team separately
          if (
            team.grandparent_team &&
            !teamMap.has(
              getTeamKey({
                team: team.grandparent_team,
                mgr_email: team.grandparent_team_dri,
                parent_team: null,
              }),
            )
          ) {
            const grandparentTeam = {
              team: team.grandparent_team,
              reference_id: team.grandparent_team_reference_id,
              mgr_email: team.grandparent_team_dri,
              parent_team: team[`extra_level_1`],
              grandparent_team: team[`extra_level_2`],
              members: [],
            };
            if (!teamMap.has(getTeamKey(grandparentTeam))) {
              allTeams.push(grandparentTeam);
              teamMap.set(getTeamKey(grandparentTeam), grandparentTeam);
              addedNewTeam = true;
            }
          }

          // Handle missing extra level teams
          this.extraLevels.forEach((level) => {
            const extraLevelTeam = team[`extra_level_${level}`];
            const extraLevelDri = team[`extra_level_${level}_dri`];
            const extraLevelReferenceId =
              team[`extra_level_${level}_reference_id`];

            if (
              extraLevelTeam &&
              !teamMap.has(
                getTeamKey({
                  team: extraLevelTeam,
                  mgr_email: extraLevelDri,
                  reference_id: extraLevelReferenceId,
                }),
              )
            ) {
              const newExtraLevelTeam = {
                team: extraLevelTeam,
                reference_id: extraLevelReferenceId,
                mgr_email: extraLevelDri,
                parent_team: null,
                grandparent_team: null,
                members: [],
              };
              if (!teamMap.has(getTeamKey(newExtraLevelTeam))) {
                allTeams.push(newExtraLevelTeam);
                teamMap.set(getTeamKey(newExtraLevelTeam), newExtraLevelTeam);
                addedNewTeam = true;
                console.log(
                  `Added extra level ${level} team: ${newExtraLevelTeam.team}`,
                  newExtraLevelTeam,
                );
              }
            }
          });
        });
      } while (addedNewTeam);

      return allTeams;
    },
    toggleSquad(targetSquad) {
      this.squads = this.squads.map((squad) => {
        if (targetSquad.temp_id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
        }

        if (targetSquad.temp_id === squad.temp_id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (targetSquad.expanded) {
            this.expandedSquadsIds[targetSquad.temp_id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.temp_id];
          }
        }

        return squad;
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.temp_id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    recalculateTree() {
      this.preview();
    },
    submit(markAsPreview = false) {
      const url = "/admin/org_csvs";

      const params = {
        squads: this.squads,
        associations_only: this.customSettings.associationsOnly,
        user_memberships_only: this.customSettings.userMembershipsOnly,
        tree_type: this.treeType,
        reset_all_teams: this.customSettings.resetAllTeams,
        rows: this.rows,
        save_for_preview: markAsPreview,
        team_uniqueness: this.customSettings.teamUniqueness,
      };
      console.log(`params:`, params);

      postData(url, params).then((res) => {
        console.log(`res:`, res);
        if (res.import_id) {
          this.activeImportId = res.import_id;
          this.activeImportEncodedId = res.import_encoded_id;
          if (!markAsPreview) {
            this.poll();
          } else {
            window.location.href = `/admin/org_csvs/preview/${this.activeImportEncodedId}`;
          }
        }
      });
    },
    updateImport() {
      const url = `/admin/org_csvs/${this.activeImportId}`;
      const payload = {
        customer_approved_at: new Date().toISOString(),
        squads: this.squads,
        team_uniqueness: this.customSettings.teamUniqueness,
      };
      putData(url, payload).then((res) => {
        if (res.success) {
          this.activePreviewableImport = {
            ...this.activePreviewableImport,
            customer_approved_at: new Date().toISOString(),
          };
        }
      });
    },
    poll() {
      if (!this.activeImportId) return;
      const fullUrl = `/admin/org_csvs/${this.activeImportId}/progress`;
      this.intervalId = setInterval(async () => {
        try {
          const response = await fetch(fullUrl);
          const data = await response.json();

          this.progress = (data.items_processed / data.total_items) * 100 || 0;
          console.log(`progress:`, this.progress);
          this.progressPayload = data;

          // Stop polling if job is complete
          if (this.progress >= 100 || this.progressPayload.failed_at) {
            clearInterval(this.intervalId);
          }
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      }, 1000); // NOTE: POLLING INTERVAL
    },
    downloadSourceCsv() {
      // Convert rows_json back to CSV format
      const rows = this.parsedRows;
      const headers = Object.keys(rows[0]);

      // Create CSV string starting with headers
      let csv = headers.join(",") + "\n";

      // Add each row of data
      rows.forEach((row) => {
        const values = headers.map((header) => {
          const value = row[header];
          // Handle values that need quotes (contain commas or quotes)
          if (value && (value.includes(",") || value.includes('"'))) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value || "";
        });
        csv += values.join(",") + "\n";
      });

      const blob = new Blob([csv], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "source.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    downloadJSON() {
      const json = JSON.stringify(this.squads, null, 2); // Convert squads to JSON
      const blob = new Blob([json], { type: "application/json" }); // Create a Blob
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href to the Blob URL
      a.download = "squads.json"; // Set the download attribute
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor from the document
      URL.revokeObjectURL(url); // Clean up the URL object
    },
    uniqueMembers(data) {
      const uniqueMembers = data.reduce((accumulator, squad) => {
        // Check if members exist and is an array
        if (Array.isArray(squad.members)) {
          // Add each member to the accumulator
          squad.members.forEach((member) => {
            accumulator.add(member); // Use a Set to store unique values
          });
        }
        return accumulator;
      }, new Set());

      return uniqueMembers;
    },
  };
}
